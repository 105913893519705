import React from "react"

export default function MedarbetareLong({ medarbetare }) {
  const medarbetare1 = medarbetare?.slice(0, 1)
  const medarbetare2 = medarbetare?.slice(1, 2)
  const medarbetare3 = medarbetare?.slice(2, 3)
  const medarbetare4 = medarbetare?.slice(3, 4)
  const medarbetare5 = medarbetare?.slice(4, 5)
  const medarbetare6 = medarbetare?.slice(5, 6)
  const medarbetare7 = medarbetare?.slice(6, 7)
  return (
    <div className="grid md:grid-cols-2 md:gap-8">
      <div>
        <div className="">
          {medarbetare1?.map(post => {
            return <MedarbetareLongSingle key={post.namn} post={post} />
          })}
        </div>
        <div className="">
          {medarbetare3?.map(post => {
            return <MedarbetareLongSingle key={post.namn} post={post} />
          })}
        </div>
        <div className="">
          {medarbetare2?.map(post => {
            return <MedarbetareLongSingle key={post.namn} post={post} />
          })}
        </div>
        <div className="">
          {medarbetare7?.map(post => {
            return <MedarbetareLongSingle key={post.namn} post={post} />
          })}
        </div>
      </div>

      <div>
        <div className="">
          {medarbetare4?.map(post => {
            return <MedarbetareLongSingle key={post.namn} post={post} />
          })}
        </div>
        <div className="">
          {medarbetare5?.map(post => {
            return <MedarbetareLongSingle key={post.namn} post={post} />
          })}
        </div>
        <div className="">
          {medarbetare6?.map(post => {
            return <MedarbetareLongSingle key={post.namn} post={post} />
          })}
        </div>
      </div>
    </div>
  )
}

function MedarbetareLongSingle({ post }) {
  const { kortText, namn, titel, telefonnummer, epost } = post
  return (
    <div className="mb-8">
      <div className="font-bold text-lg">
        <div>
          {namn}
          {titel ? ", " : ""}
          {titel}
        </div>
      </div>
      <div
        dangerouslySetInnerHTML={{ __html: kortText }}
        className="space-y-4"
      />
      <div className="my-4 border-l-8 border-primary pl-4 text-sm">
        <div className="text-xs">
          <div>Phone: {telefonnummer}</div>
          <div>Epost: {epost}</div>
        </div>
      </div>
    </div>
  )
}
