import React from "react"
import { GatsbyImage, getImage } from "gatsby-plugin-image"

export default function MainPageFeaturedImage({ img }) {
  const image = getImage(img?.node?.localFile.childImageSharp)
  return (
    <div id="featured-image">
      <GatsbyImage image={image} alt="Bodecker Partners" />
      {img?.node?.caption && (
        <div
          dangerouslySetInnerHTML={{ __html: img.node.caption }}
          className="text-xs bg-primary py-2 px-4 mb-0"
        />
      )}
    </div>
  )
}
