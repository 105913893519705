import React from "react"

export default function IngressPage({ text }) {
  return (
    <div className=" text-xl md:text-2xl serif tracking-normal ">
      <div className="leading-relaxed">
        <div dangerouslySetInnerHTML={{ __html: text }} className="space-y-4" />
      </div>
    </div>
  )
}
